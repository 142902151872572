import Craftsman from '@/common/craftsmanResolver'
import { useEmployerStore } from '@/store/employerStore'
import { useCraftsMasterStore } from '@/store/craftsMasterStore'
import { TestDataLimit } from '@/common/craftsMasterResolver'

import Chance from 'chance'
import moment from 'moment'

const chance = Chance()

const Status = {
	Created: 0, // employer created but not posted
	Posted: 1, // job order posted but vendor hasn't started adding craftsmen
	InProgress: 2, // vendor has added at least one craftsman but not submitted job order
	Submitted: 3, // at least one vendor has submitted craftsmen
	Closed: 4, // craftsmen placements have been filled
	Declined: 5 // vendor declined job order
}

const Column = {
	Created: 0, // employer
	Posted: 1, // employer
	Responded: 2, // employer
	Received: 3, // vendor
	InProgress: 4, // vendor
	Sent: 5 // vendor
}

const Statuses = [
	{
		value: Status.Created,
		text: 'Created',
		column: Column.Created,
		eColumn: Column.Created,
		vColumn: Column.Created,
		color: '#ECEFF1'
	}, {
		value: Status.Posted,
		text: 'Posted',
		column: Column.Posted,
		eColumn: Column.Posted,
		vColumn: Column.Received,
		color: '#CFD8DC'
	}, {
		value: Status.InProgress,
		text: 'In Progress',
		column: Column.Posted,
		eColumn: Column.Posted,
		vColumn: Column.InProgress,
		color: '#B0BEC5'
	}, {
		value: Status.Submitted,
		text: 'Submitted',
		column: Column.Responded,
		eColumn: Column.Responded,
		vColumn: Column.Sent,
		color: '#90A4AE'
	}, {
		value: Status.Closed,
		text: 'Closed',
		column: Column.Responded,
		eColumn: Column.Responded,
		vColumn: Column.Sent,
		color: '#78909C'
	}, {
		value: Status.Declined,
		text: 'Declined',
		column: Column.Responded,
		eColumn: Column.Responded,
		vColumn: Column.Sent,
		color: '#546E7A'
	}
]

const Columns = [
	{
		value: Column.Created,
		text: 'Created',
		color: 'red'
	}, {
		value: Column.Posted,
		text: 'Posted',
		color: 'green'
	}, {
		value: Column.Responded,
		text: 'Responded',
		color: 'blue'
	}, {
		value: Column.Received,
		text: 'Received',
		color: 'red'
	}, {
		value: Column.InProgress,
		text: 'In Progress',
		color: 'green'
	}, {
		value: Column.Sent,
		text: 'Sent',
		color: 'blue'
	}
]

export const Craft = {
	Assembler: 0,
	BoilerSpecialist: 1,
	ConstructionManager: 2,
	Electrician: 3,
	IndustrialMechanic: 4,
	IronWorker: 5,
	LeadSupervisor: 6,
	MachineOperator: 7,
	Machinist: 8,
	MarineTigerCrew: 9,
	Millwright: 10,
	OutsideMachinist: 11,
	Pipefitter: 12,
	PipeWelder: 13,
	Plumber: 14,
	SheetMetalMechanic: 15,
	ShipFitter: 16,
	StructuralWelder: 17,
	UnderwaterWelder: 18
}

export const Crafts = [
	{
		value: Craft.Assembler,
		text: 'Assembler',
		altText: 'Asmblr',
		code: 'AS'
	}, {
		value: Craft.BoilerSpecialist,
		text: 'Boiler Specialist',
		altText: 'Blr Spc',
		code: 'BS'
	}, {
		value: Craft.ConstructionManager,
		text: 'Construction Manager',
		altText: 'Con Mgr',
		code: 'CM'
	}, {
		value: Craft.Electrician,
		text: 'Electrician',
		altText: 'Elec',
		code: 'EL'
	}, {
		value: Craft.IndustrialMechanic,
		text: 'Industrial Mechanic',
		altText: 'Ind Mech',
		code: 'IM'
	}, {
		value: Craft.IronWorker,
		text: 'Iron Worker',
		altText: 'Iron Wrk',
		code: 'IW'
	}, {
		value: Craft.LeadSupervisor,
		text: 'Lead Supervisor',
		altText: 'Ld Sup',
		code: 'LS'
	}, {
		value: Craft.MachineOperator,
		text: 'Machine Operator',
		altText: 'Mach Oper',
		code: 'MO'
	}, {
		value: Craft.Machinist,
		text: 'Machinist-Manual & CNC',
		altText: 'Mach-Man CNC',
		code: 'MC'
	}, {
		value: Craft.MarineTigerCrew,
		text: 'Marine Tiger Crew',
		altText: 'Mar Tig Crw',
		code: 'MT'
	}, {
		value: Craft.Millwright,
		text: 'Millwright',
		altText: 'Mlwt',
		code: 'MW'
	}, {
		value: Craft.OutsideMachinist,
		text: 'Outside Machinist',
		altText: 'Out Mach',
		code: 'OM'
	}, {
		value: Craft.Pipefitter,
		text: 'Pipefitter',
		altText: 'Ppf',
		code: 'PF'
	}, {
		value: Craft.PipeWelder,
		text: 'Pipe Welder',
		altText: 'Pp Weld',
		code: 'PW'
	}, {
		value: Craft.Plumber,
		text: 'Plumber',
		altText: 'Plb',
		code: 'PL'
	}, {
		value: Craft.SheetMetalMechanic,
		text: 'Sheet Metal Mechanic',
		altText: 'Sh Met Mech',
		code: 'SM'
	}, {
		value: Craft.ShipFitter,
		text: 'Ship Fitter',
		altText: 'Shp Fit',
		code: 'SF'
	}, {
		value: Craft.StructuralWelder,
		text: 'Structural Welder',
		altText: 'Str Weld',
		code: 'SW'
	}, {
		value: Craft.UnderwaterWelder,
		text: 'Underwater Welder',
		altText: 'Und Weld',
		code: 'UW'
	}
]

export const Class = {
	First: 0,
	Second: 1,
	Third: 2
}

export const Classes = [
	{
		value: Class.First,
		text: 'First Class',
		altText: '1st Class',
		code: '1'
	}, {
		value: Class.Second,
		text: 'Second Class',
		altText: '2nd Class',
		code: '2'
	}, {
		value: Class.Third,
		text: 'Third Class',
		altText: '3rd Class',
		code: '3'
	}
]

const Field = {
	CraftId: 0,
	ClassId: 1,
	YardAreaId: 2,
	StartDate: 3,
	EndDate: 4,
	CraftsmanCount: 5,
	BillRateLo: 6,
	BillRateHi: 7,
	PayRate: 8,
	NonLocalCandidates: 9,
	Vendors: 10,
	Notes: 11,
	JobOrderId: 12,
	Craftsmen: 13,
	EmployerId: 14,
	createdAt: 15,
	updatedAt: 16
}

const Fields = [
	{
		id: Field.CraftId,
		component: 'v-select',
		label: 'Craft',
		filterPosition: 0,
		value: null,
		required: true,
		sortPosition: 0,
		sortable: true,
		groupable: true,
		width: '9%',
		items: Crafts,
		rando: () => chance.integer({ min: 0, max: 18 })
	}, {
		id: Field.ClassId,
		component: 'v-radio-group',
		label: 'Class',
		filterPosition: 1,
		value: null,
		required: true,
		sortPosition: 1,
		sortable: true,
		width: '7%',
		radioItems: Classes,
		rando: () => chance.integer({ min: 0, max: 2 })
	}, {
		id: Field.YardAreaId,
		component: 'v-select',
		label: 'Yard',
		value: null,
		returnObject: true,
		required: true,
		filterPosition: 2,
		width: '9%',
		items: null,
		valueField: 'YardAreaId'
	}, {
		id: Field.StartDate,
		component: 'v-date-picker',
		label: 'Start Date',
		sortPosition: 5,
		sortable: true,
		value: null,
		menuValue: false,
		width: '8%',
		cols: 6,
		isDate: true,
		required: true,
		rando: () => {
			const date = moment(chance.date({ month: moment().month() + 1, year: moment().year() }))
			return date.format('YYYY-MM-DD')
		}
	}, {
		id: Field.EndDate,
		component: 'v-date-picker',
		label: 'End Date',
		sortPosition: 6,
		sortable: true,
		value: null,
		menuValue: false,
		width: '8%',
		cols: 6,
		isDate: true,
		required: true,
		rando: () => {
			const start = moment(chance.date({ month: moment().month() + 1, year: moment().year() }))
			const end = moment(start).add(chance.integer({ min: 1, max: 4 }), 'months')
			return end.format('YYYY-MM-DD')
		}
	}, {
		id: Field.CraftsmanCount,
		component: 'v-text-field',
		label: 'Count',
		sortPosition: 4,
		sortable: true,
		type: 'number',
		width: '6%',
		value: null,
		required: true,
		rules: [(v) => v > 0 || 'Please enter a Craftsmen count greater than 0'],
		rando: () => {
			const craftsMasterStore = useCraftsMasterStore()
			return chance.integer({
				min: craftsMasterStore.testDataLimits[TestDataLimit.CraftsmanCountPerJobOrder].range[0],
				max: craftsMasterStore.testDataLimits[TestDataLimit.CraftsmanCountPerJobOrder].range[1]
			})
		}
	}, {
		id: Field.BillRateLo,
		component: 'v-text-field',
		label: 'Bill Rate - Low',
		sortPosition: 7,
		sortable: true,
		type: 'number',
		isCurrency: true,
		value: null,
		prefix: '$',
		expanded: true,
		cols: 6,
		change: (value, fields) => {
			if (!fields[Field.BillRateHi].value) {
				fields[Field.BillRateHi].value = value
			}
		},
		required: true,
		rules: [(v) => v > 0 || 'Please enter a value greater than 0'],
		rando: () => chance.floating({ min: 25, max: 35, fixed: 2 }).toFixed(2)
	}, {
		id: Field.BillRateHi,
		component: 'v-text-field',
		label: 'Bill Rate - High',
		sortPosition: 7,
		sortable: true,
		isCurrency: true,
		type: 'number',
		value: null,
		prefix: '$',
		expanded: true,
		cols: 6,
		required: true,
		rules: [(v) => v > 0 || 'Please enter a value greater than 0'],
		rando: () => chance.floating({ min: 36, max: 50, fixed: 2 }).toFixed(2)
	}, {
		id: Field.PayRate,
		component: 'v-text-field',
		label: 'Pay Rate',
		sortPosition: 8,
		sortable: true,
		type: 'number',
		isCurrency: true,
		value: null,
		prefix: '$',
		width: '8%',
		required: true,
		rules: [(v) => v > 0 || 'Please enter a Pay Rate greater than 0'],
		rando: () => chance.floating({ min: 25, max: 50, fixed: 2 })
	}, {
		id: Field.NonLocalCandidates,
		component: 'v-checkbox',
		label: 'Accept Non-local Candidates?',
		expanded: true, // just don't display this in a table
		value: false,
		rando: () => chance.bool()
	}, {
		id: Field.Vendors,
		component: 'v-select',
		label: 'Vendors',
		value: null,
		multiple: true,
		returnObject: true,
		chips: true,
		filterPosition: 3,
		items: null,
		width: '8%',
		valueField: 'VendorId'
	}, {
		id: Field.Notes,
		component: 'v-textarea',
		label: 'Notes',
		expanded: true,
		value: null
	}, {
		id: Field.JobOrderId
	}, {
		id: Field.Craftsmen
	}, {
		id: Field.EmployerId
	}, {
		id: Field.createdAt,
		label: 'Created Date',
		width: '8%',
		isDateTime: true,
		sortable: true,
		sortPosition: -1
	}, {
		id: Field.updatedAt,
		label: 'Updated Date',
		width: '8%',
		isDateTime: true,
		sortable: true,
		sortPosition: -2
	}
]

const FieldNames = Object.keys(Field)

const getValidationObject = (jobOrder) => {
	const requiredFields = Fields.filter(f => f.required)
	const validation = requiredFields.reduce((validation, f) => {
		validation[FieldNames[f.id]] = {
			...f,
			hasValue: (jobOrder[FieldNames[f.id]] || jobOrder[FieldNames[f.id]] === 0) && jobOrder[FieldNames[f.id]].value !== null
		}
		return validation
	}, {})
	return validation
}

const allRequiredFieldsHaveValues = (jobOrder) => {
	const validation = getValidationObject(jobOrder)
	const isValid = Object.entries(validation).every(f => f[1].hasValue)
	return isValid
}

export default {
	Icon: 'mdi-clipboard-edit-outline',
	IconAlert: 'mdi-clipboard-alert-outline',
	IconNavigate: 'mdi-clipboard-arrow-right-outline',
	IconInactive: 'mdi-clipboard-off-outline',
	IconHistory: 'mdi-clipboard-text-clock-outline',
	Field,
	Fields,
	Status,
	Statuses,
	FieldNames,
	Column,
	Columns,
	Craft,
	Crafts,
	Class,
	Classes,
	getStatus (jobOrder) {
		let status = jobOrder.Posted ? Status.Posted : Status.Created
		const jobOrderStatuses = new Set()
		// *** Available craftsmen by definition won't appear in the Vendors collection on a job order
		jobOrder.Craftsmen.forEach(joc => {
			switch (joc.StatusId) {
				case Craftsman.Status.Submitted:
				case Craftsman.Status.InterviewRequested:
				case Craftsman.Status.InterviewScheduled:
				case Craftsman.Status.OfferPending:
				case Craftsman.Status.OfferExtended:
				case Craftsman.Status.OfferAccepted:
				case Craftsman.Status.Onboarding:
				case Craftsman.Status.OnAssignment:
				case Craftsman.Status.CompletedAssignment:
				case Craftsman.Status.LeftAssignment:
				case Craftsman.Status.Rejected:
					jobOrderStatuses.add(Status.Submitted)
					break
			}
		})
		// roll up job order status
		if (jobOrderStatuses.has(Status.Submitted)) {
			status = Status.Submitted
		}
		return status
	},
	getVendorStatus ({ vendor, jobOrder }) {
		let status = vendor.hasOwnProperty('PostedDate') && vendor.PostedDate ? Status.Posted : Status.Created
		if (jobOrder) {
			const craftsmanIds = jobOrder.Craftsmen
				.filter(c => c.VendorId === vendor.VendorId && c.StatusId !== Craftsman.Status.Available)
				.map(c => c.CraftsmanId)
			if (craftsmanIds.length > 0) {
				status = Status.Submitted
			}
		}
		return status
	},
	// Updates a job order's rolled up status and the job order's Vendors collection statuses
	// based on the passed in job order's Craftsmen statuses, which must be updated first
	// 		--craftsmen statuses from the craftsmanStore will be updated elsewhere--
	// further, all job orders begin with a Created status and an empty Craftsmen collection,
	// so a Craftsmen collection with length > 0 will change a job order's Created status and
	// therefore the Created status need not be considered here
	// setStatus (jobOrder) {
	// 	const jobOrderStatuses = new Set()
	// 	const vendorStatuses = []
	// 	jobOrder.Vendors.forEach(v => {
	// 		vendorStatuses[v.VendorId] = new Set()
	// 	})
	// 	// job order craftsmen must come from job order vendors
	// 	// *** Available craftsmen by definition won't appear in the Vendors collection on a job order
	// 	jobOrder.Craftsmen.forEach(joc => {
	// 		switch (joc.StatusId) {
	// 			case Craftsman.Status.Submitted:
	// 			case Craftsman.Status.InterviewRequested:
	// 			case Craftsman.Status.InterviewScheduled:
	// 			case Craftsman.Status.OfferPending:
	// 			case Craftsman.Status.OfferExtended:
	// 			case Craftsman.Status.OfferAccepted:
	// 			case Craftsman.Status.Onboarding:
	// 			case Craftsman.Status.OnAssignment:
	// 			case Craftsman.Status.CompletedAssignment:
	// 			case Craftsman.Status.LeftAssignment:
	// 			case Craftsman.Status.Rejected:
	// 				jobOrderStatuses.add(Status.Submitted)
	// 				vendorStatuses[joc.VendorId].add(Status.Submitted)
	// 				break
	// 		}
	// 	})
	// 	// set each vendor status--this refers to the job order's status for a particular vendor;
	// 	// for example, if a vendor has added 8 craftsmen to a job order but submitted 3, then the
	// 	// vendor status for this job order would be Submitted
	// 	for (const id in vendorStatuses) {
	// 		const statuses = vendorStatuses[id]
	// 		const vendor = jobOrder.Vendors.find(v => v.VendorId === id)
	// 		// roll up vendor status
	// 		if (statuses.has(Status.Submitted)) {
	// 			vendor.StatusId = Status.Submitted
	// 		} else if (vendor.StatusId === Status.Sent) {
	// 			// job order status defaults to Created, and can only
	// 			// become Sent if a vendor has received the job order
	// 			jobOrder.StatusId = Status.Sent
	// 		}
	// 	}
	// 	// roll up job order status
	// 	if (jobOrderStatuses.has(Status.Submitted)) {
	// 		jobOrder.StatusId = Status.Submitted
	// 	}
	// },
	getFields (employerId) {
		const employerStore = useEmployerStore()
		const employerVendors = employerStore.employerVendors(employerId)
		employerVendors.sort((a, b) => {
			return ('' + a.text).localeCompare(b.text)
		})
		const employer = employerStore.employers.find(e => e.EmployerId === employerId)
		const yards = []

		const fields = Fields.map(f => {
			switch (f.id) {
				case Field.Vendors:
					return Object.assign({}, f, {
						items: employerVendors
					})
				case Field.YardAreaId:
					if (employer) {
						employer.Yards.forEach(y => {
							y.Areas.forEach((area, index) => {
								yards.push({
									value: `${y.YardId}.${index}`,
									text: `${y.Name} (${area})`,
									YardAreaId: `${y.YardId}.${index}`
								})
							})
						})
					}
					return Object.assign({}, f, {
						items: yards
					})
				default:
					return { ...f }
			}
		})
		return fields
	},
	getRequiredFields () {
		return Fields.filter(f => f.required)
	},
	allRequiredFieldsHaveValues,
	getValidationObject,
	canBePosted (jobOrder) {
		const isJobOrderValid = allRequiredFieldsHaveValues(jobOrder)

		if (isJobOrderValid) {
			return jobOrder.Vendors?.length > 0
		} else {
			return false
		}
	},
	getCraftIdByCraftName (name) {
		return Crafts.find(c => c.text === name).value
	},
	getClassIdByClassName (name) {
		return Classes.find(c => c.text === name || c.altText === name).value
	},
	getJobTitle (jobOrder) {
		const employerStore = useEmployerStore()
		const employer = employerStore.employers.find(e => e.EmployerId === jobOrder.EmployerId)
		const employerName = employer ? employer.Name : ''
		const yardAreaId = jobOrder.YardAreaId.split('.')
		const yard = employer.Yards.find(y => y.YardId === yardAreaId[0])
		const yardName = yard.Name
		const yardArea = yard.Areas[yardAreaId[1]]

		return {
			craftClassJobOrderId: `
				<div class="font-weight-bold text-truncate">${Crafts[jobOrder.CraftId].altText}-${Classes[jobOrder.ClassId].altText}</div>
				<div class="text-truncate">${jobOrder.JobOrderId}</div>
			`,
			name: `${Crafts[jobOrder.CraftId].text}, ${Classes[jobOrder.ClassId].text}`,
			nameAlt: `${Crafts[jobOrder.CraftId].text}, ${Classes[jobOrder.ClassId].altText}`,
			nameTwoLine: `<div class="text-truncate">${employerName}</div><div>${jobOrder.JobOrderId}</div>`,
			nameTwoLineFormatted: `<div class="text-truncate text-subtitle-2">${employerName}</div><div class="text-caption">${jobOrder.JobOrderId}</div>`,
			nameTwoLineAlt: `${employerName}<br />${Crafts[jobOrder.CraftId].altText}--${Classes[jobOrder.ClassId].altText}`,
			nameThreeLineFormatted: `
				<div class="text-truncate text-subtitle-2">${employerName}</div>
				<div class="text-caption">${Crafts[jobOrder.CraftId].altText}--${Classes[jobOrder.ClassId].altText}</div>
				<div class="text-caption">${jobOrder.JobOrderId}</div>
			`,
			yard: `${yardName}, ${yardArea}`,
			yardTwoLine: `${yardName}<br />${yardArea}`,
			yardJobOrderIdTwoLine: `<div class="text-truncate">${yardName}, ${yardArea}</div><div>${jobOrder.JobOrderId}</div>`
		}
	}
}
